import {BaseCartController} from '../../domain/controllers/BaseCartController';
import {CartLayout, ICartStyleSettings} from '../../types/app.types';
import {SIDE_CART_ORIGIN} from '../../common/constants';
import styleParams from './stylesParams';
import {StyleParamType} from '@wix/tpa-settings';

export class SideCartController extends BaseCartController {
  protected getOrigin() {
    return SIDE_CART_ORIGIN;
  }

  protected getCartLayout(): CartLayout {
    return CartLayout.SIDE_CART;
  }

  protected getDefaultSettings() {
    return {
      booleans: Object.keys(styleParams)
        .filter((param) => styleParams[param].type === StyleParamType.Boolean)
        .reduce((acc, key) => {
          acc[key] = styleParams[key].getDefaultValue();
          return acc;
        }, {}),
    } as unknown as ICartStyleSettings;
  }

  /* istanbul ignore next */
  public exports() {
    return {};
  }

  /* istanbul ignore next */
  public getFreeTexts(): string[] {
    return [];
  }
}
